import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import StyledButton from "../../Components/StyledButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import banner from "../../assets/images/customer-details-banner.b8a58de92348b3619281.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import avatar from "../../assets/images/avatar.jpg";
import UserSocialApps from "../../Components/AppUsers/UserSocialApps";
import ChangeSinglePlanDialog from "../../Components/AppUsers/ChangeSinglePlanDialog";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "left",
  color: "#000",
  // border: "2px solid red",
}));

const Title = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  marginBottom: "5px",
}));

const Content = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  marginBottom: "15px",
}));
const AWS_S3_BUCKET_URL=process.env.REACT_APP_AWS_S3_BUCKET_URL;
const CustomerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [details, setDetails] = useState();
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleReload = () => setReload(!reload);
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-user/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        response.status === 200 && setIsLoading(false);
        setDetails(response?.data?.user);
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    }

    fetchData();
  }, [navigate, id, reload]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          margin: "20px 30px",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #66656536" }}>
          <p>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Home
            </Link>{" "}
            /{" "}
            <Link
              to="/customers"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Customers
            </Link>{" "}
            / <strong>Customer Details</strong>
          </p>
          <h4>Customers Details</h4>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: 2 }}>
          <Box
            sx={{
              backgroundImage: `url(${banner})`,
              minHeight: "180px",
              // padding: "20px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "15px",
                top: "130px",
                margin: "auto",
                width: "100px",
                height: "100px",
              }}
            >
              <img
                src={
                  details?.profileImage !== null
                    ? `https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${details?.profileImage}`
                    : avatar
                }
                alt="QR Code"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  border: "5px solid #ffff",
                  objectFit: "cover",
                }}
              />
              {details?.UserSubscription?.Card?.CardType?.name === "Pro" && (
                <LocalPoliceIcon
                  sx={{
                    fontSize: "24px",
                    color: "#FFD700",
                    position: "absolute",
                    top: "74px",
                    left: "70px",
                    zIndex: 100,
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                position: "absolute",
                right: "15px",
                top: "0",
                bottom: "0",
                margin: "auto",
                width: "100px",
                height: "100px",
              }}
            >
              <img
                src={details?.UserSubscription?.qrCode}
                alt="QR Code"
                style={{
                  borderRadius: "6px",
                  width: "100px",
                  height: "100px",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: "70px" }}>
            <h4>{details?.fullName}</h4>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexWrap={"wrap"}
            >
              <Stack direction="row" spacing={1}>
                <Box>
                  <EmailOutlinedIcon fontSize="15px" />
                  <span style={{ fontSize: "15px", marginLeft: "5px" }}>
                    {details?.email}
                  </span>
                  {/* <CallIcon  style={{ fontSize: "15px", marginLeft: "5px" }}/> */}
                </Box>
                <Box>
                  <LocalPhoneIcon fontSize="15px" sx={{ marginLeft: "8px" }} />
                  <span style={{ fontSize: "10px", marginLeft: "5px" }}>
                    {details?.phonenumber}
                  </span>
                </Box>
              </Stack>
              <Stack direction="row" spacing={1}>
                {details && (
                  <ChangeSinglePlanDialog
                    reload={handleReload}
                    id={id}
                    currentPlan={
                      details?.UserSubscription?.Card?.CardType?.name
                    }
                  />
                )}

                <StyledButton
                  text={
                    <>
                      <LanguageIcon
                        sx={{
                          fontSize: "20px",
                          color: "#04459d",
                          marginRight: "8px",
                        }}
                      />
                      Preview
                    </>
                  }
                  variant="outlined"
                  onClick={() => {
                    window.open(
                      `https://web-app.connct.ai/user-profile.html?activation-key=${details?.UserSubscription?.activationKey}`,
                      "_blank"
                    );
                  }}
                />
              </Stack>
            </Stack>
            <Grid container>
              <Grid item xs={12} sm={12} sx={{ marginTop: "28px" }}>
                <Item>
                  <h5
                    style={{
                      marginBottom: "0.5rem",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    User Info
                  </h5>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Address</Title>
                  <Content>
                    {details?.UserSubscription?.userData?.address ||
                      details?.UserSubscription?.Transaction?.address}
                  </Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>DOB</Title>
                  <Content>{details?.birthday}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Gender</Title>
                  <Content>{details?.gender}</Content>
                </Item>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} sx={{ marginTop: "28px" }}>
                <Item>
                  <h5
                    style={{
                      marginBottom: "0.5rem",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    Account Info
                  </h5>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Plan Name</Title>
                  <Content>
                    {details?.UserSubscription?.Card?.CardType?.name}
                  </Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Card Type</Title>
                  <Content>{details?.UserSubscription?.Card?.type}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Product</Title>
                  <Content>{details?.UserSubscription?.cardStyle}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ marginTop: "25px" }}>
                <Item>
                  <Title>Activation Key</Title>
                  <Content>{details?.UserSubscription?.activationKey}</Content>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <UserSocialApps details={details} />
        </Box>
      </Box>
    </>
  );
};

export default CustomerDetails;
