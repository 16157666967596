import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(
  ({ theme, variant, setcolor, hovercolor }) => ({
    color: variant === "outlined" ? setcolor : "#fff",
    backgroundColor: variant === "outlined" ? "#fff" : setcolor,
    fontSize: "12px",
    padding: "8px 25px",
    border: `1px solid ${setcolor}`,
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: variant === "outlined" ? "#fff" : hovercolor,
      color: variant === "outlined" ? hovercolor : "#fff",
    },
  })
);

const StyledButton = ({
  variant = "contained",
  text,
  onClick,
  color = "#04459d",
  hovercolor = "#286090",
}) => {
  return (
    <ColorButton
      onClick={onClick}
      variant={variant}
      setcolor={color}
      hovercolor={hovercolor}
    >
      {text}
    </ColorButton>
  );
};

export default StyledButton;
