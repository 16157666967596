import React from "react";
import {BrowserRouter as Router,Routes,Navigate,Route,} from "react-router-dom";
import "./index.css";
import Home from "./Components/Home.jsx";
import Signup from "./pages/Login/Signup";
import ForgotPassowrd from "./pages/Login/Forgotpassword";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Activation from "./pages/Activation";
import ProfileCreation from "./pages/ProfileCreation.jsx";
import Orders from "./pages/Orders/Orders";
import Customers from "./pages/AppUsers/Customers";
import Newsletter from "./pages/Newsletter";
import Coupons from "./pages/Coupons";
import SignOut from "./pages/SignOut";
import Blogs from "./pages/Blogs/Blogs";
import "./Components/NavBar/Navbar.css";
import Category from "./pages/Blogs/Category";
import SubCategory from "./pages/Blogs/SubCategory";
import CreateBlog from "./pages/Blogs/CreateBlog.jsx";
import BlogDetails from "./pages/Blogs/BlogDetails";
import UpdateBlog from "./pages/Blogs/UpdateBlog";
import UpdateCategory from "./pages/Blogs/UpdateCategory";
import UpdateSubCategory from "./pages/Blogs/UpdateSubCategory";
import CustomerDetails from "./pages/AppUsers/CustomerDetails";
import OrderDetails from "./pages/Orders/OrderDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navigate to="/dashboard" replace={true} />
            </>
          }
        />
        <Route path="/" element={<Home />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="activation" element={<Activation />} />
          <Route path="profileCreation" element={<ProfileCreation/>} />
          <Route path="orders" element={<Orders />} />
          <Route path="vieworder/:id" element={<OrderDetails />} />
          <Route path="customers" element={<Customers />} />
          <Route path="customer-details/:id" element={<CustomerDetails />} />
          <Route path="news-letter" element={<Newsletter />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="sign-out" element={<SignOut />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blog-details/:id" element={<BlogDetails />} />
          <Route path="edit-blog/:id" element={<UpdateBlog />} />
          <Route path="edit-category/:id" element={<UpdateCategory />} />
          <Route path="edit-subcategory/:id" element={<UpdateSubCategory />} />
          <Route path="category" element={<Category />} />
          <Route path="sub-category" element={<SubCategory />} />
          <Route path="create-blog" element={<CreateBlog />} />
        </Route>
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassowrd />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="dark"
        toastClassName="toaster"
      />
    </Router>
  );
};

export default App;
