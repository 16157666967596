import React, { useState } from "react";
import { NavLink } from "react-router-dom"; // Import Link component
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";

function Navbar({ isToggled }) {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="sidebar-wrapper">
      <div className={`${isToggled ? "toggled" : ""} sidebar`}>
        <div className="logo-div">
          <img
            src={require("../../assets/images/c.png")}
            alt="Logo"
            className="img-logo"
            style={{ width: "200px" }}
          />
        </div>
        <div className="sidebar-nav">
          <NavLink to="/dashboard" className="nav-btn">
            <i className="fas fa-th-large"></i>
            <span style={{ marginLeft: "7px" }}>Dashboard</span>
          </NavLink>
          <NavLink to="/activation" className="nav-btn">
            <i className="fas fa-keyboard"></i>
            <span style={{ marginLeft: "7px" }}>Activation</span>
          </NavLink>
          <NavLink to="profilecreation" className="nav-btn">
            <i className="fas fa-user-friends"></i>
            <span style={{ marginLeft: "7px" }}>Profile Creation</span>
          </NavLink>
          <NavLink to="/orders" className="nav-btn">
            <i className="fas fa-shopping-cart"></i>
            <span style={{ marginLeft: "7px" }}>Orders</span>
          </NavLink>

          <NavLink to="/customers" className="nav-btn">
            <i className="fas fa-user-friends"></i>
            <span style={{ marginLeft: "7px" }}>App Users</span>
          </NavLink>
          <div
            className="nav-btn"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <i className="fas fa-pen-square"></i>
            <span style={{ marginLeft: "7px" }}>Blogs</span>
            <i
              className={`fas ${
                showDropdown ? "fa-chevron-down" : "fa-chevron-right"
              }`}
              style={{ float: "right", marginTop: "5px" }}
              // onClick={() => setShowDropdown(!showDropdown)}
            ></i>
          </div>
          <div className={`${showDropdown ? "" : "hide-dropdown"} dropdown`}>
            <NavLink
              to="/blogs"
              className={({ isActive }) => {
                if (isActive) {
                  // setShowDropdown(true);
                  return "nav-btn active";
                }
                return "nav-btn";
              }}
            >
              Blog
            </NavLink>
            <NavLink
              to="/category"
              className={({ isActive }) => {
                if (isActive) {
                  // setShowDropdown(true);
                  return "nav-btn active";
                }
                return "nav-btn";
              }}
            >
              {/* {({ isActive }) => isActive && setShowDropdown(true)} */}
              Category
            </NavLink>
            <NavLink
              to="/sub-category"
              className={({ isActive }) => {
                if (isActive) {
                  // setShowDropdown(true);
                  return "nav-btn active";
                }
                return "nav-btn";
              }}
            >
              {/* {({ isActive }) => isActive && setShowDropdown(true)} */}
              Sub Category
            </NavLink>
          </div>
          <NavLink to="/news-letter" className="nav-btn">
            <i className="fas fa-newspaper"></i>
            <span style={{ marginLeft: "7px" }}>News Letter</span>
          </NavLink>
          <NavLink to="/coupons" className="nav-btn">
            <i className="fas fa-gift"></i>
            <span style={{ marginLeft: "7px" }}>Coupons</span>
          </NavLink>
          <NavLink to="/sign-out" className="nav-btn">
            <i className="fas fa-sign-out-alt"></i>
            <span style={{ marginLeft: "7px" }}>Sign Out</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
